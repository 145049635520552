import styled from "styled-components";

export const Wrapper = styled.div`
  height: 350px;
  margin: 0;
  padding-left: 20px;
`;

export const Title = styled.div`
  padding-left: 20px;
  padding-bottom: 20px;
`;
